<template>
  <div>
    <v-content>
      <v-container fluid>
        <v-layout align-center justify-center>
          <v-flex xs12 sm10 md6 lg5>
            <v-card class="elevation-8 pa-10 mt-16">
              <v-img class="mx-auto" width="300" src="/logo.png" />
              <h1 class="text-center mt-10">Masiya Sales</h1>
              <v-card-text>
                <v-form
                  @submit.prevent="submit"
                  id="check-login-form"
                  class="my-5"
                >
                  <v-text-field
                    class="mb-8"
                    outlined
                    rounded
                    prepend-icon="mdi-account"
                    v-model="email"
                    label="Email"
                    :error="!!(errors && errors.detail)"
                    :error-messages="
                      errors && errors.detail ? errors.detail : null
                    "
                    :hide-details="!(errors && errors.detail)"
                    type="text"
                  ></v-text-field>
                  <v-text-field
                    class="mb-8"
                    outlined
                    rounded
                    id="password"
                    prepend-icon="mdi-lock"
                    v-model="password"
                    :error="!!(errors && errors.detail)"
                    hide-details
                    label="Password"
                    type="password"
                  ></v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-btn
                  type="submit"
                  form="check-login-form"
                  class="px-16 mx-auto"
                  :loading="loading"
                  :disabled="loading"
                  @click="submit"
                  x-large
                  color="primary"
                  >Login</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-content>
  </div>
</template>

<script>
export default {
  name: "Login",
  data: () => ({
    email: null,
    password: null,
    loading: false,
    errors: null,
  }),
  components: {},
  methods: {
    submit() {
      this.loading = true;
      this.errors = true;
      this.$http({
        method: this.$store.getters["auth/login"].method,
        url: this.$store.getters["auth/login"].url,
        headers: this.$store.getters["auth/login"].headers,
        data: {
          email: this.email,
          password: this.password,
        },
      })
        .then((response) => {
          let fullname = response.data.name;
          this.$store.commit("SetUser", { fullname, role: response.data.role });
          this.$store.commit("SetAccess", { token: response.data.token });
          this.$router.push({ name: "Dashboard" });
        })
        .catch((err) => {
          this.errors = err.response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
